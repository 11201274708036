<script setup>
import { menuConference } from '@/configs/menu';

const mobileMenuVisible = defineModel('mobileMenuVisible', {
  type: Boolean,
  default: false,
});
const toggleMobileMenuVisible = useToggle(mobileMenuVisible);
</script>

<template>
  <div class="layout-header-conference container">
    <div class="layout-header-conference__logos">
      <NuxtLink to="/">
        <img
          class="layout-header-conference__image layout-header-conference__image--logo"
          src="/images/logo-white.svg"
          alt="Logo"
        />
      </NuxtLink>

      <NuxtLink to="https://vamprivet.ru/" target="_blank">
        <img
          class="layout-header-conference__image layout-header-conference__image--logo-mir"
          src="/images/logo-mir.svg"
          alt="Logo Mir"
        />
      </NuxtLink>

      <NuxtLink to="https://sbp.nspk.ru/" target="_blank">
        <img
          class="layout-header-conference__image layout-header-conference__image--logo-sbp"
          src="/images/logo-sbp.svg"
          alt="Logo Sbp"
        />
      </NuxtLink>
    </div>

    <nav class="layout-header-conference__menu hidden-mobile">
      <NuxtLink
        v-for="menuItem in menuConference"
        :key="menuItem.name"
        class="layout-header-conference__link"
        :to="menuItem.url"
      >
        <span
          class="layout-header-conference__font layout-header-conference__font--menu"
        >
          {{ $t(`conference.navLinks.${menuItem.name}`) }}
        </span>
      </NuxtLink>
    </nav>

    <ButtonsBurger
      class="layout-header-conference__link hidden-desktop"
      :opened="mobileMenuVisible"
      @click="toggleMobileMenuVisible()"
    />
  </div>
</template>

<style scoped lang="scss">
.layout-header-conference {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-green-1;
  padding-top: em(31);
  padding-bottom: em(10);

  @include media-breakpoint-down(sm) {
    padding: em(6) em(12);
  }

  &__logos {
    display: flex;
    align-items: center;
    gap: em(38);

    @include media-breakpoint-down(sm) {
      gap: em(21);
    }
  }

  &__image {
    &--logo {
      width: em($logo-width);
      height: em($logo-height);

      @include media-breakpoint-down(sm) {
        width: em($logo-width-sm);
        height: em($logo-height-sm);
      }
    }

    &--logo-mir {
      width: em(101);
      height: em(27);

      @include media-breakpoint-down(sm) {
        width: em(56);
        height: em(15);
      }
    }

    &--logo-sbp {
      width: em(108);
      height: em(58);

      @include media-breakpoint-down(sm) {
        width: em(59);
        height: em(32);
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: em(28);
  }

  &__link {
    color: $color-green-2;
    transition: color $time-normal;

    @include hover-active-focus {
      color: $color-white;
    }
  }

  &__font {
    &--menu {
      font-size: em(24);
      line-height: 1;
    }
  }
}
</style>
